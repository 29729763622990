import _ from 'lodash';

/**
 * Validation method to check if the given value is undefined or not.
 * @description - Uses lodash's `isUndefined` method to check the value
 *
 * @param {any} data - Data can be of any type (string, number,...)
 *
 * @returns {boolean} - Returns true if value is undefined, else false
 */
export function isUndefined(data: unknown): boolean {
    return _.isUndefined(data);
}

/**
 * Validation method to check if the given value is null or not.
 * @description - Uses lodash's `isNull` method to check the value
 *
 * @param {any} data - Data can be of any type (string, number,...)
 *
 * @returns {boolean} - Returns true if value is null, else false
 */
export function isNull(data: unknown): boolean {
    return _.isNull(data);
}

/**
 * Validation method to check if the given value is neither undefined nor null.
 * @description - Uses another validation helper method `isUndefined` and `isNull`.
 *
 * @see isUndefined
 * @see isNull
 *
 * @param {any} data - Data can be of any type (string, number,...)
 *
 * @returns {boolean} - Returns true if value is neither undefined nor null, else false
 * @author Bibishan Pandey
 */
export function isNetherUndefinedNorNull(data: unknown): boolean {
    return !isUndefined(data) && !isNull(data);
}

export const statusProps = (status: string) => {

    let currentStatus = status;
    let bulletClassName = "bg-orange-1";
    let capsuleClassName = "bg-orange-2";

    if (!status) {
        currentStatus = "document.status.new_document";
        bulletClassName = "bg-black-1";
        capsuleClassName = "bg-black-4";
        return {
            currentStatus,
            bulletClassName,
            capsuleClassName
        }
    }

    if (status?.includes("failed")) {
        bulletClassName = "bg-red-300";
        capsuleClassName = "bg-red-200";
        return {
            currentStatus,
            bulletClassName,
            capsuleClassName
        }
    }

    switch (status) {
        case "document.status.annotating":
            bulletClassName = "bg-orange-1";
            capsuleClassName = "bg-orange-2";
            break;
        case "document.status.annotated":
            bulletClassName = "bg-green-1";
            capsuleClassName = "bg-green-2";
            break;
        case "document.status.anonymizing":
            bulletClassName = "bg-orange-1";
            capsuleClassName = "bg-orange-2";
            break;
        case "document.status.anonymized":
            bulletClassName = "bg-brand-2";
            capsuleClassName = "bg-brand-3";
            break;
        case "document.status.saving":
            bulletClassName = "bg-yellow-1";
            capsuleClassName = "bg-yellow-2";
            break;
        case "document.status.saved":
            bulletClassName = "bg-green-1";
            capsuleClassName = "bg-green-2";
            break;
        case "document.status.creating_new_document":
            bulletClassName = "bg-yellow-1";
            capsuleClassName = "bg-yellow-2";
            break;
        case "document.status.created_new_document":
            bulletClassName = "bg-green-1";
            capsuleClassName = "bg-green-2";
            break;
        default:
            break;
    }


    return {
        currentStatus,
        bulletClassName,
        capsuleClassName
    }
};
