export enum ToastId {
    OTP_SUCCESS_TOAST = 'otp_success_toast_id',
    OTP_ERROR_TOAST = 'otp_error_toast_id',
    DOCUMENT_SUCCESS_TOAST = 'document_success_toast_id',
    DOCUMENT_ERROR_TOAST = 'document_error_toast_id',
    LOGIN_SUCCESS_TOAST = 'otp_success_toast_id',
    LOGIN_ERROR_TOAST = 'otp_error_toast_id',
    ANNOTATION_SUCCESS_TOAST = 'annotation_success_toast_id',
    ANNOTATION_ERROR_TOAST = 'annotation_error_toast_id',
    MEMBER_SUCCESS_TOAST = 'member_success_toast_id',
    UPDATE_ROLE_SUCCESS_TOAST = 'update_role_success_toast_id',
    UPDATE_ROLE_FAILED_TOAST = "update_role_failed_toast_id",
    MEMBER_ERROR_TOAST = 'member_error_toast_id',
    PROJECT_SUCCESS_TOAST = 'project_success_toast_id',
    PROJECT_ERROR_TOAST = 'project_error_toast_id',
    ORGANIZATION_SUCCESS_TOAST = 'organization_success_toast_id',
    ORGANIZATION_ERROR_TOAST = 'organization_error_toast_id',
    LOGS_SUCCESS_TOAST = 'logs_success_toast_id',
    LOGS_ERROR_TOAST = 'logs_error_toast_id',
    INVITATION_SUCCESS_TOAST = 'invitation_success_toast_id',
    INVITATION_ERROR_TOAST = 'invitation_error_toast_id',
    VALIDATION_ERROR_TOAST = 'validation_error_toast_id',
    SUCCESS_TOAST = 'success_toast_id',
    ERROR_TOAST = "error_toast_id"

}
