import {atom, useAtom} from 'jotai';

export type MODAL_VIEW =
    'DELETE_PDF'
    | 'LOADING_MODAL'
    | 'DELETE_PROJECT'
    | 'REMOVE_MEMBER'
    | "ROLE"
    | "REMOVE_INVITATION"
    | "FILTER_BY_MODAL";

const modalAtom = atom({isOpen: false, view: '', modalProps: null});

export function useModal() {
    const [state, setState] = useAtom(modalAtom);
    const openModal = (view: MODAL_VIEW, modalProps: any = null) =>
        setState({
            ...state,
            isOpen: true,
            view,
            modalProps
        });
    const closeModal = () => {
        setState({...state, isOpen: false, modalProps: null, view: ''});
    };

    return {
        ...state,
        openModal,
        closeModal
    };
}
