import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react';

import environments from '@app/configs/environments';
import {AccessTokenRequestBody} from '@app/store/auth/types';
import {callbackResponseDto} from "@app/models/dtos/callbackResponseDto";


export const USER_PATH = 'user';

export const userApi = createApi({
    reducerPath: USER_PATH,
    // refetchOnMountOrArgChange: true,
    // refetchOnReconnect: true,
    // refetchOnFocus: true,
    // keepUnusedDataFor: 0,
    baseQuery: fetchBaseQuery({
        baseUrl: environments.CLIENT_SIDE_ENDPOINT_HOST + '/api',
        credentials: 'include',
        prepareHeaders(headers) {
            headers.set('Access-Control-Allow-Origin', environments.CLIENT_SIDE_ENDPOINT_HOST as string);
            headers.set("Content-Type", "application/json");
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getAuthUrl: builder.query<any, string>({
            query: (locale) => ({
                url: '/oauth/authorization',
                method: 'GET',
                params: {
                    locale
                }
            })
        }),
        getAccessToken: builder.query<callbackResponseDto, AccessTokenRequestBody>({
            query: (requestBody) => ({
                url: '/oauth/callback',
                method: 'POST',
                body: requestBody
            })
        }),
        handleLogout: builder.mutation<string, void>({
            query: () => ({
                url: '/user/logout',
                method: 'POST',
            })
        })
    })
});

export const {useLazyGetAuthUrlQuery, useGetAccessTokenQuery, useHandleLogoutMutation} = userApi;
